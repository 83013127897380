import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { Wrapper } from '@farewill/ui'

import FrequentlyAskedQuestions from 'components/FrequentlyAskedQuestions'

const FAQ = (): React.ReactElement => {
  const { faqSection } = useStaticQuery(graphql`
    query {
      faqSection: contentfulFaqSection(
        contentful_id: { eq: "3aImMHwBRmv76RqUTGhWqi" }
      ) {
        ...FaqSectionFields
      }
    }
  `)

  return (
    <Wrapper container>
      <FrequentlyAskedQuestions
        title="Common questions about legacy fundraising"
        questions={faqSection.questions}
        customAmount={4}
      />
    </Wrapper>
  )
}

export default FAQ
