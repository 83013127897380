import React, { useCallback, useMemo, useRef } from 'react'
import styled from 'styled-components'
import { useDevicePixelRatio, useReducedMotion } from '@reactuses/core'
import { useMeasure, useIntersection } from 'react-use'

import { GTR } from '@farewill/ui/tokens'
import { CLOUDINARY_ROOT_PATH } from '@farewill/ui/components/Image/constants'

import { SCROLLING_LOGO_LIST } from 'lib/charities/constants/logos'
import { useAnimationFrame } from 'lib/hooks/useAnimationFrame'

import { usePreloadImages } from './usePreloadImages'

const MAX_IMAGE_WIDTH = 180
const MAX_IMG_HEIGHT = parseInt(GTR.XXL, 10)
const GAP = parseInt(GTR.XL, 10)
const SPEED = 50 // pixels per second

const StyledWrapper = styled.div`
  user-select: none;
  mask-image: linear-gradient(
    var(--mask-direction, to right),
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 20%,
    hsl(0 0% 0% / 1) 80%,
    hsl(0 0% 0% / 0)
  );
`
const ScrollingCharitiesList = (): React.ReactElement => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [measureRef, { width: canvasWidth }] = useMeasure<HTMLDivElement>()
  const intersection = useIntersection(canvasRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0,
  })
  const { pixelRatio } = useDevicePixelRatio()
  const reduceMotion = useReducedMotion(false)

  // We must round to the nearest pixel here to avoid 500 errors from Cloudinary
  // which doesn't support non-integer heights. When a user has browser zoom set
  // to anything other than 100% this can result in devicePixelRatio being a
  // non-integer, which will cause Cloudinary to throw an error.
  const srcImageHeight = Math.round(MAX_IMG_HEIGHT * pixelRatio)

  const imageSrcs = useMemo(
    () =>
      SCROLLING_LOGO_LIST.map(
        (image) =>
          `${CLOUDINARY_ROOT_PATH}/c_scale,w_auto,h_${srcImageHeight},f_auto,q_auto/${image.imagePath}.png`
      ),
    [pixelRatio]
  )
  const { loadedImages } = usePreloadImages(imageSrcs)
  const xOffset = useRef(0)

  const totalWidth = useMemo(
    () =>
      loadedImages.reduce((acc, img) => {
        let imgWidth = img.width / pixelRatio
        if (img.height > img.width) {
          imgWidth = MAX_IMG_HEIGHT * img.ratio
        } else {
          imgWidth = Math.min(imgWidth, MAX_IMAGE_WIDTH)
        }

        return acc + GAP + imgWidth
      }, 0),
    [loadedImages]
  )

  const drawImages = useCallback(
    (ctx: CanvasRenderingContext2D, startX: number) => {
      let currentX = startX

      loadedImages.forEach((img) => {
        let imgWidth
        let imgHeight
        let yOffset

        if (img.height > img.width) {
          imgHeight = MAX_IMG_HEIGHT
          imgWidth = imgHeight * img.ratio
        } else {
          imgWidth = Math.min(img.width / pixelRatio, MAX_IMAGE_WIDTH)
          imgHeight = imgWidth / img.ratio
          yOffset = (MAX_IMG_HEIGHT - imgHeight) / 2
        }

        currentX += GAP
        ctx.drawImage(
          img.element,
          0,
          0,
          img.width,
          img.height,
          currentX,
          yOffset || 0,
          imgWidth,
          imgHeight
        )
        currentX += imgWidth
      })
      return currentX
    },
    [loadedImages]
  )

  const animate = useCallback(
    (deltaTime: number) => {
      const canvas = canvasRef.current
      if (!canvas || loadedImages.length === 0) return

      const ctx = canvas.getContext('2d')
      if (!ctx) return

      ctx.clearRect(0, 0, canvas.width, canvas.height)

      // Update xOffset without modulo
      if (reduceMotion) {
        xOffset.current = 0
      } else {
        xOffset.current += SPEED * deltaTime
      }

      // If xOffset exceeds totalWidth, reset it to create a seamless loop
      if (xOffset.current >= totalWidth) {
        xOffset.current -= totalWidth
      }

      let currentX = -xOffset.current

      // Draw the first set of images
      currentX = drawImages(ctx, currentX)

      // Draw a second set of images to create a seamless loop
      drawImages(ctx, currentX)
    },
    [drawImages, loadedImages, reduceMotion, totalWidth]
  )

  useAnimationFrame(
    animate,
    loadedImages.length > 0 && (intersection?.isIntersecting ?? false),
    reduceMotion
  )

  return (
    <StyledWrapper ref={measureRef}>
      <canvas
        data-percy-hide
        height={MAX_IMG_HEIGHT}
        width={canvasWidth}
        ref={canvasRef}
        style={{ width: '100%', height: `${MAX_IMG_HEIGHT}px` }}
      />
    </StyledWrapper>
  )
}

export default ScrollingCharitiesList
