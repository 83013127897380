import React from 'react'
import styled from 'styled-components'
import { Wrapper, H, Button } from '@farewill/ui'
import { COLOR } from '@farewill/ui/tokens'

import WrapperWithEdge from 'components/WrapperWithEdge'

import CallToAction from '../CallToAction'

const StyledWrapper = styled(Wrapper)`
  position: relative;
  overflow: hidden;
`

const StyledHeader = styled(H)`
  max-width: 20ch;
`

const Footer = () => (
  <WrapperWithEdge direction="up" edgeBackgroundColor={COLOR.WHITE}>
    <StyledWrapper
      centered
      noTrim
      padding={['L', 0, 'L']}
      paddingFromM={['XL', 0, 'XXL']}
    >
      <StyledHeader margin={[0, 'auto', 'XL']} size="M" decorative>
        Ready to transform your legacy fundraising?
      </StyledHeader>

      <Wrapper centered>
        <CallToAction
          stretchOnMobile={false}
          tag={Button.White}
          trackingId="footer"
        >
          Partner with Farewill
        </CallToAction>
      </Wrapper>
    </StyledWrapper>
  </WrapperWithEdge>
)

export default Footer
