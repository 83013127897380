import React from 'react'
import styled from 'styled-components'
import { Wrapper, P } from '@farewill/ui'
import { COLOR, GTR, FONT } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'

import { PARTNER_HUB_URL, SPOTLIGHT_URL } from 'config'

const StyledStrong = styled.strong`
  font-weight: ${FONT.WEIGHT.MEDIUM};
  margin-right: ${GTR.XXS};
`

const StyledLineBreak = styled.br`
  ${screenMin.l`
    display: none;
  `}
`

const Banner = (): React.ReactElement => (
  <Wrapper background={COLOR.ACCENT.SECONDARY}>
    <Wrapper container containerPaddingTop={0} containerPaddingBottom={0}>
      <Wrapper>
        <P color={COLOR.WHITE} padding={[GTR.XS, 0]} size="S">
          <StyledStrong>Already a Farewill partner?</StyledStrong>{' '}
          <StyledLineBreak />
          Log in to your <a href={SPOTLIGHT_URL}>Dashboard</a> or your{' '}
          <a href={PARTNER_HUB_URL}>Partner hub</a>
        </P>
      </Wrapper>
    </Wrapper>
  </Wrapper>
)

export default Banner
