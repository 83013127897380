import React from 'react'
import styled from 'styled-components'

import { Image, H, P, Wrapper } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'

import { CLOUDINARY_ROOT_PATH } from '@farewill/ui/components/Image/constants'
import { FormatPathArgs } from '@farewill/ui/components/Image/types'
import { screenMin } from '@farewill/ui/helpers/responsive'
import { headingM, headingS } from '@farewill/ui/helpers/text'

const StyledH = styled(H)`
  ${headingS}

  ${screenMin.l`
    ${headingM}
  `}
`
const StyledParagraph = styled(P)`
  max-width: 820px;
`

const StyledAwardList = styled.ul`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${GTR.L};

  ${screenMin.m`
    flex-direction: row;
    justify-content: center;
    gap: ${GTR.XL};
  `}
`

const StyledAwardListItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${GTR.S};

  ${screenMin.m`
    max-width: 50%;
    flex-direction: row;
    text-align: center;
  `}
`

const StyledFigure = styled.figure`
  margin: 0;
`

const StyledCaption = styled(P)`
  max-width: 25ch;
`

const StyledAwardImage = styled(Image)<{ $width: number }>`
  width: ${({ $width }) => $width}px;
`

const AwardImage = ({
  alt,
  imagePath,
  caption,
  width,
}: {
  alt: string
  imagePath: string
  caption: React.ReactNode
  width: number
}) => (
  <StyledFigure>
    <StyledAwardImage
      $width={width}
      alt={alt}
      path={imagePath}
      formatPath={({ path, ext }: FormatPathArgs) =>
        `${CLOUDINARY_ROOT_PATH}/c_scale,f_auto,q_auto,w_${width}/${path}.${ext}`
      }
    />
    <StyledCaption
      centeredFromL
      color={COLOR.BLACK}
      tag="figcaption"
      margin={[GTR.M, 0, 0]}
      size="S"
    >
      {caption}
    </StyledCaption>
  </StyledFigure>
)

const AwardWinningService = (): React.ReactElement => (
  <Wrapper container margin={[0, 'auto']} maxWidthInColumns={10}>
    <StyledH centeredFromL size="M">
      Give your supporters an award-winning service
    </StyledH>
    <StyledParagraph margin={[0, 'auto', GTR.L]}>
      Our will-writing service is optimised for charity giving: it’s already
      raised over £1 billion in pledges. A&nbsp;great experience for your
      supporters means a higher return on investment for your&nbsp;organisation.
    </StyledParagraph>

    <StyledAwardList>
      <StyledAwardListItem>
        <AwardImage
          alt="The British Wills & Probate Awards - National Will Writing Firm of the Year 2019-2022"
          caption={<>National Will Writing Firm of the Year 2019-2022</>}
          imagePath="external-logos/british-wills-and-probate-awards"
          width={190}
        />
      </StyledAwardListItem>
      <StyledAwardListItem>
        <AwardImage
          alt="Smee & Ford - Best Strategic Will Provider Highly commended 2024"
          caption={
            <>Best Strategic Will Provider Highly&nbsp;commended&nbsp;2024</>
          }
          imagePath="external-logos/s-f-legacy-giving-awards"
          width={177}
        />
      </StyledAwardListItem>
    </StyledAwardList>
  </Wrapper>
)

export default AwardWinningService
