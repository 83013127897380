export const BUSINESS_UNIT_ID = '5811a7fe0000ff0005969ea6'

export const WIDGET_TEMPLATE_ID = {
  SLIDER: '54ad5defc6454f065c28af8b',
  HORIZONTAL: '5406e65db0d04a09e042d5fc',
  DROPDOWN: '5418052cfbfb950d88702476',
  CAROUSEL: '53aa8912dec7e10d38f59f36',
} as const

export const TAGS = {
  PROBATE: 'ProbateSelected',
  FAVOURITES: 'SelectedReview',
  FAVOURITES_ALL_PRODUCTS: 'FavouritesAllProducts',
  PARTNERSHIPS: 'PartnershipsFavourites',
}

export type Tag = (typeof TAGS)[keyof typeof TAGS]
