import React from 'react'

import { COLOR } from '@farewill/ui/tokens'

import ThreeEasySteps from 'components/ThreeEasySteps'
import CallToAction from '../CallToAction'

const WhyCharitiesChooseFarewill = (): React.ReactElement => (
  <ThreeEasySteps
    background={COLOR.ACCENT.PRIMARY_20}
    headingSize="M"
    title={<>Why charities choose Farewill</>}
    steps={[
      {
        imagePath: 'illustrations/blob-cat-hello-yellow',
        heading: 'It’s easy to set up your legacy programme',
        text: 'We’ll get to know you before we support you with your first legacy campaign. We’ve worked with charities (and budgets) of every shape and size, in every cause area. We’ll tailor our support to fit, whether you’re just starting out or you’re a seasoned legacy manager.',
      },
      {
        imagePath: 'illustrations/blob-with-laptop-yellow',
        heading: 'Get marketing support from legacy experts',
        imageWidthFromL: 230,
        text: `You’ll get your own, branded free-wills landing page to share with your supporters. You’ll also have access to specialist marketing tools, legacy giving campaign ideas, insights and resources. We’ve supported hundreds of charity wills programmes and we know what works.`,
      },
      {
        imagePath: 'illustrations/blob-with-calculator-yellow',
        imageWidthFromL: 238,
        heading: 'Optimise campaigns and steward relationships',
        text: 'Track legacy gifts and campaign success in real-time. Use supporter demographics to optimise your marketing campaigns and dashboard data to forecast future income. Our reports are designed to share – with your team, your board or your CEO.',
      },
    ]}
    cta={
      <CallToAction trackingId="why-charities-choose">
        Partner with Farewill
      </CallToAction>
    }
  />
)

export default WhyCharitiesChooseFarewill
