import { useState, useEffect } from 'react'

type PreloadedImage = {
  element: HTMLImageElement
  height: number
  ratio: number
  src: string
  width: number
}

export const usePreloadImages = (
  images: string[]
): { loadedImages: PreloadedImage[] } => {
  const [loadedImages, setLoadedImages] = useState<PreloadedImage[]>([])

  useEffect(() => {
    let isMounted = true
    const preloadedImages: (PreloadedImage | null)[] = new Array(
      images.length
    ).fill(null)

    Promise.all(
      images.map(
        (src, index) =>
          new Promise<void>((resolve) => {
            const img = new Image()
            img.onload = () => {
              const preloadedImage: PreloadedImage = {
                src,
                element: img,
                height: img.naturalHeight,
                ratio: img.naturalWidth / img.naturalHeight,
                width: img.naturalWidth,
              }
              preloadedImages[index] = preloadedImage
              resolve()
            }

            // Load the image
            img.src = src
          })
      )
    ).then(() => {
      if (isMounted) {
        // If any images failed to load, remove the from the array we're going
        // to return.
        setLoadedImages(
          preloadedImages.filter((img): img is PreloadedImage => img !== null)
        )
      }
    })

    return () => {
      isMounted = false
    }
  }, [images])

  return { loadedImages }
}
