import React from 'react'
import { createGlobalStyle } from 'styled-components'
import { COLOR } from '@farewill/ui/tokens'

import DefaultLayout from 'layouts/DefaultLayout'
import PledgeCounterBanner from 'components/PledgeCounterBanner'

import Banner from './components/Banner'
import Partners from './components/Partners'
import Hero from './components/Hero'
import Footer from './components/Footer'
import WhyCharitiesChooseFarewill from './components/WhyCharitiesChoose'
import AwardWinningService from './components/AwardWinningService'
import SupportFromExperts from './components/SupportFromExperts'
import Trustpilot from './components/Trustpilot'
import FAQ from './components/FAQ'

const GlobalBodyStyles = createGlobalStyle`
  body {
    background: ${COLOR.WHITE};
  }`

const Charities = (): React.ReactElement => (
  <DefaultLayout
    title="Legacy Giving Programs for Charities | Free wills by Farewill"
    description="Boost your charity’s legacy income with Farewill’s free will service for supporters. Our legacy giving programs help charities grow donations and build long-term donor relationships. Learn more today."
  >
    <GlobalBodyStyles />

    <Banner />
    <Hero />
    <PledgeCounterBanner />
    <Partners />
    <WhyCharitiesChooseFarewill />
    <AwardWinningService />
    <SupportFromExperts />
    <Trustpilot />
    <FAQ />

    <Footer />
  </DefaultLayout>
)

export default Charities
