import React from 'react'

import LoaderSpinner from 'components/LoaderSpinner'
import type { PledgeCounterProps } from './counter'

const Counter = React.lazy(
  () =>
    import('./counter' /* webpackChunkName: "ClientSideOnlyPledgeCounter" */)
)

/**
 * Work around the 3rd-party library we're using for the counter not being
 * SSR-compatible.
 *
 * See https://github.com/pqina/flip/issues/44
 */
const PledgeCounterWrapper = (
  props: PledgeCounterProps
): React.ReactElement => {
  const isSSR = typeof window === 'undefined'

  return (
    <>
      {!isSSR && (
        <React.Suspense fallback={<LoaderSpinner />}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Counter {...props} />
        </React.Suspense>
      )}
    </>
  )
}

export default PledgeCounterWrapper
