import React from 'react'
import styled from 'styled-components'

import { H, Wrapper } from '@farewill/ui'
import { screenMin } from '@farewill/ui/helpers/responsive'
import { COLOR } from '@farewill/ui/tokens'
import { CLOUDINARY_ROOT_PATH } from '@farewill/ui/components/Image/constants'
import type { FormatPathArgs } from '@farewill/ui/components/Image/types'

// import PledgeCounter from 'components/PledgeCounter'
import Texture from 'components/Texture'
import PledgeCounter from 'components/PledgeCounter'

const StyledWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  place-content: center;
  position: relative;
  text-align: center;
  z-index: 1;
`

const StyledTextureSwoop = styled(Texture)`
  left: -140px;
  max-width: 400px;
  min-width: 270px;
  position: absolute;
  top: -30px;
  width: 40vw;
  z-index: -1;
`

const StyledTextureDots = styled(Texture)`
  position: absolute;
  bottom: -20px;
  min-width: 345px;
  right: -50px;
  width: 50vw;
  z-index: -1;

  ${screenMin.m`
    right: 0;
  `};
`

const StyledTextureBlob = styled(Texture)`
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 690px;
  min-width: 340px;
  translate: 20% 70%;
  width: 50vw;
  z-index: -2;
`

const StyledCounterWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-grow: 1;
  width: 100%;

  & > * {
    max-width: 648px;
    flex-grow: 1;
    width: 100%;
  }
`

const PledgeCounterBanner = () => (
  <StyledWrapper
    background={COLOR.ACCENT.PRIMARY}
    padding={['XXL', 'S']}
    paddingFromM={['120px', 'L']}
  >
    <H size="M" margin={[0, 0, 'L']}>
      Over £1 billion pledged for charity
    </H>
    <StyledCounterWrapper>
      <PledgeCounter />
    </StyledCounterWrapper>
    <StyledTextureSwoop
      formatPath={({ path, ext, width }: FormatPathArgs) =>
        `${CLOUDINARY_ROOT_PATH}/c_scale,f_auto,q_auto,a_90,w_${width}/${path}.${ext}`
      }
      loading="eager"
      path="textures/sketchy-swoop-yellow-02_ha58tm"
      width={400}
    />
    <StyledTextureDots
      loading="eager"
      path="textures/dots-pattern-yellow-02"
      width={400}
    />
    <StyledTextureBlob
      formatPath={({ path, ext, width }: FormatPathArgs) =>
        `${CLOUDINARY_ROOT_PATH}/c_scale,f_auto,q_auto,a_270,w_${width}/${path}.${ext}`
      }
      loading="eager"
      path="textures/blob-yellow-4"
      width={300}
    />
  </StyledWrapper>
)

export default PledgeCounterBanner
