/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'

import { Button } from '@farewill/ui'
import { ButtonProps } from '@farewill/ui/components/Button/types'
import { BREAKPOINT } from '@farewill/ui/tokens'

import { CHARITIES_REQUEST_CALLBACK_URL } from 'lib/charities/constants'
import BreakpointSwitch from 'components/BreakpointSwitch'
import ExternalLink from 'components/ExternalLink'

const CallToAction = ({
  children,
  tag: Tag = Button.Primary,
  stretchOnMobile = true,
  trackingId,
  ...props
}: {
  children: string
  stretchOnMobile?: boolean
  trackingId: string
  tag?: React.FunctionComponent<React.PropsWithChildren<ButtonProps>>
}): React.ReactElement => {
  const baseProps = {
    'data-track-event': 'click',
    'data-track-element': 'cta',
    'data-track-slice': trackingId,
    href: CHARITIES_REQUEST_CALLBACK_URL,
    tag: ExternalLink,
    ...props,
  }

  return (
    <BreakpointSwitch
      aboveComponent={<Tag {...baseProps}>{children}</Tag>}
      belowComponent={
        <Tag {...baseProps} stretch={stretchOnMobile}>
          {children}
        </Tag>
      }
      breakAt={BREAKPOINT.M}
    />
  )
}

export default CallToAction
