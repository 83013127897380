import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Button } from '@farewill/ui'
import { TRUSTPILOT_URL } from 'config'

const StyledLinkWrapper = styled.div`
  z-index: 2;
  opacity: 0;
  height: 0;
  text-align: center;

  /**
   * This stops the button from showing a cursor pointer on mouseover, but
   * allows it to continue receiving focus through keyboard navigation.
   */
  pointer-events: none;

  &:focus-within {
    height: auto;
    opacity: 1;
  }
`

const SkipToReviews = (): React.ReactElement => (
  <StyledLinkWrapper>
    <Button.Plain href={TRUSTPILOT_URL} target="_blank" rel="noreferrer">
      Read our reviews on Trustpilot
    </Button.Plain>
  </StyledLinkWrapper>
)

interface LoadTruspilotWidgetProps {
  widget: React.RefObject<HTMLElement>
  children: React.ReactNode
}

const LoadTrustpilotWidget = ({
  widget,
  children,
}: LoadTruspilotWidgetProps): React.ReactElement => {
  useEffect(() => {
    if (window.Trustpilot && widget) {
      window.Trustpilot.loadFromElement(widget.current)
      widget.current?.children[0]?.setAttribute('tabindex', '-1')
      widget.current?.children[0]?.setAttribute('aria-hidden', 'true')
    }
  }, [widget])

  return (
    <>
      <SkipToReviews />
      {children}
    </>
  )
}

export default LoadTrustpilotWidget
