import React from 'react'
import styled from 'styled-components'
import { Wrapper, Grid, H, P, Image } from '@farewill/ui'
import { BREAKPOINT, COLOR, GTR } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'
import {
  headingXL,
  headingL,
  paragraphM,
  paragraphL,
} from '@farewill/ui/helpers/text'

import PreventOrphan from 'components/PreventOrphan'
import TrustpilotSummary from 'components/TrustpilotWidget/TrustpilotSummary'
import BreakpointSwitch from 'components/BreakpointSwitch'

import CallToAction from '../CallToAction'

const StyledGrid = styled(Grid)`
  --padding: ${GTR.L};
  padding-bottom: var(--padding);
  padding-top: var(--padding);

  ${screenMin.l`
    --padding: ${GTR.XXL};
  `}
`

const StyledHeading = styled(H)`
  ${headingL}

  ${screenMin.m`
    ${headingXL}
  `}
`

const StyledSubtitle = styled(P)`
  ${paragraphM}

  ${screenMin.m`
    ${paragraphL}
  `}
`

const StyledImage = styled(Image)`
  position: relative;
  top: -20px;
`

const Hero = (): React.ReactElement => (
  <Wrapper background={COLOR.WHITE}>
    <StyledGrid container gap={0} gapFromL="L">
      <Grid.Item spanFromL={6}>
        <StyledHeading decorative>
          <PreventOrphan>
            Unlock your full legacy potential with Farewill
          </PreventOrphan>
        </StyledHeading>

        <StyledSubtitle margin={[0, 0, 'L']}>
          Grow your charity’s legacy income and build lifelong supporter
          relationships. Our trusted free will service is designed to inspire
          legacy giving.
        </StyledSubtitle>
        <CallToAction trackingId="hero">Partner with Farewill</CallToAction>
        <Wrapper margin={['L', 0, 0]}>
          <TrustpilotSummary />
        </Wrapper>
      </Grid.Item>

      <BreakpointSwitch
        breakAt={BREAKPOINT.M}
        belowComponent={null}
        aboveComponent={
          <Grid.Item spanFromM={6} startColumnFromM={7} centered spanRows={2}>
            <StyledImage
              path="illustrations/who-can-witness-and-sign-a-will-yellow"
              width={300}
              widthFromM={419}
              stretch
            />
          </Grid.Item>
        }
      />
    </StyledGrid>
  </Wrapper>
)

export default Hero
