import React, { useRef } from 'react'
import styled, { css } from 'styled-components'

import { screenMax, screenMin } from '@farewill/ui/helpers/responsive'
import { COLOR, GTR } from '@farewill/ui/tokens'
import { Wrapper } from '@farewill/ui'

import {
  BUSINESS_UNIT_ID,
  TAGS,
  Tag,
  WIDGET_TEMPLATE_ID,
} from 'lib/trustpilot/constants'
import LoadTrustpilotWidget from '../LoadTrustpilotWidget'

// Seems like Trustpilot widget is a bit buggy, when it comes to mobile
// This overwrites default height, to show the carousel on mobile as well
const StyledWrapper = styled.div`
  iframe {
    @media screen and (max-width: 568px) {
      height: 300px !important;
    }
  }
`

const StyledTrustpilotWrapper = styled(Wrapper)`
  ${screenMax.s`
    padding: 0 12px;
  `}
`

const StyledTrustpilotWrapperInner = styled(Wrapper)<{
  $backgroundColorDesktop: string
}>`
  ${({ $backgroundColorDesktop }) =>
    $backgroundColorDesktop &&
    css`
      background-color: ${COLOR.WHITE};

      ${screenMin.m`
        background-color: ${$backgroundColorDesktop};
      `}
    `}
  padding: ${GTR.M} 0;

  ${screenMin.l`
    padding: ${GTR.L} ${GTR.M};
  `}
`
export interface PropsTrustpilotCarousel {
  backgroundInnerDesktop?: string
  tag?: Tag
  container?: boolean
  className?: string
}

const TrustpilotCarousel = ({
  backgroundInnerDesktop = COLOR.BACKGROUND.FOG,
  tag = TAGS.FAVOURITES,
  container = true,
  className,
}: PropsTrustpilotCarousel): React.ReactElement => {
  const trustbannerElement = useRef<HTMLDivElement>(null)

  return (
    <StyledTrustpilotWrapper className={className} container={container}>
      <StyledTrustpilotWrapperInner
        $backgroundColorDesktop={backgroundInnerDesktop}
      >
        <LoadTrustpilotWidget widget={trustbannerElement}>
          <StyledWrapper>
            <div
              ref={trustbannerElement}
              data-locale="en-GB"
              data-template-id={WIDGET_TEMPLATE_ID.CAROUSEL}
              data-businessunit-id={BUSINESS_UNIT_ID}
              data-theme="light"
              data-stars="5"
              data-schema-type="Organization"
              data-style-height="150px"
              data-style-width="100%"
              data-tags={tag}
              data-review-languages="en"
              data-text-color="#0F1010"
            />
          </StyledWrapper>
        </LoadTrustpilotWidget>
      </StyledTrustpilotWrapperInner>
    </StyledTrustpilotWrapper>
  )
}

export default TrustpilotCarousel
