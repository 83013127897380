import React from 'react'
import styled from 'styled-components'
import { Wrapper, Grid, H, P } from '@farewill/ui'
import { screenMin } from '@farewill/ui/helpers/responsive'
import { FONT, GTR, COLOR } from '@farewill/ui/tokens'

import Step, { StepProps } from './Step'

const StyledGrid = styled(Grid)`
  align-items: center;

  ${screenMin.m`
    justify-items: right;
  `}
`

const StyledP = styled(P)`
  font-weight: ${FONT.WEIGHT.BOLD};
`

type ThreeEasyStepsProps = {
  background?: string
  headingMaxWidthInColumns?: number
  headingSize?: 'S' | 'M' | 'L'
  title: React.ReactNode
  cta: React.ReactNode
  ctaPrompt?: string
  steps: Array<StepProps>
}

/**
 * A component intended to display a list of steps / reasons with corresponding
 * illustrations. Despite the name, you are not limited to only 3 steps, but
 * using more / less might not look great.
 */
const ThreeEasySteps = ({
  background = COLOR.ACCENT.PRIMARY_10,
  headingMaxWidthInColumns = 6,
  headingSize = 'L',
  steps,
  title,
  cta,
  ctaPrompt,
}: ThreeEasyStepsProps): React.ReactElement => (
  <Wrapper background={background}>
    <Wrapper container>
      <H
        size={headingSize}
        maxWidthInColumns={headingMaxWidthInColumns}
        margin={[0, 'auto', GTR.L]}
        centeredFromM
        decorative
      >
        {title}
      </H>

      <StyledGrid
        padding={[0, 0, 'XL']}
        paddingFromM={['M', 0, 'L']}
        columns="8"
        gap={[0, 'M']}
        gapFromL={['M', 'XL']}
      >
        {steps.map((step) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Step key={step.heading} {...step} />
        ))}
      </StyledGrid>

      <Wrapper padding={[0, 0, 'L']} centered>
        {ctaPrompt && (
          <>
            <StyledP color={COLOR.BLACK} margin={[0, 0, GTR.L]}>
              {ctaPrompt}
            </StyledP>
          </>
        )}
        {cta}
      </Wrapper>
    </Wrapper>
  </Wrapper>
)

export default ThreeEasySteps
