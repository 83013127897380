import React, { ReactElement } from 'react'
import styled, { css } from 'styled-components'
import { Image, P, Wrapper } from '@farewill/ui'
import { FONT, COLOR, GTR } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'

import ExternalLink from 'components/ExternalLink'
import { AWARD_LOGO_URL } from 'lib/awards/constants'
import { roundDownToNearestHundred } from 'lib/formatting/numbers'
import {
  TRUSTPILOT_REVIEWS_TOTAL,
  TRUSTPILOT_RATING_OUT_OF_5,
  TRUSTPILOT_URL,
} from 'config'

const StyledParagraph = styled(P)`
  font-size: ${FONT.SIZE.XS};
  margin-bottom: ${GTR.XS};

  ${screenMin.l`
    font-size: ${FONT.SIZE.S};
  `};

  ${({ $isDark }) =>
    $isDark &&
    css`
      color: ${COLOR.BLACK};
      margin-bottom: ${GTR.XXS};
    `}
`

const StyledText = styled.span`
  color: ${COLOR.BLACK};
  font-weight: ${FONT.WEIGHT.REGULAR};
`

const StyledStrongText = styled.span`
  color: ${COLOR.BLACK};
  font-weight: ${FONT.WEIGHT.BOLD};
`

const StyledDivider = styled.span<{ $isDark?: boolean }>`
  display: inline-block;
  margin: 0 ${GTR.XS};
  color: ${COLOR.GREY.LIGHT};

  ${({ $isDark }) =>
    $isDark &&
    css`
      color: ${COLOR.BLACK};
    `}
`

type TrustpilotSummaryProps = {
  className?: string
  showLabel?: boolean
  showLabelRating?: boolean
  showText?: boolean
  hideReviewNumber?: boolean
  isCompact?: boolean
  isDark?: boolean
}

const TrustpilotSummary = ({
  className,
  showLabel = true,
  showLabelRating,
  showText = true,
  hideReviewNumber,
  isCompact,
  isDark,
}: TrustpilotSummaryProps): ReactElement => (
  <Wrapper className={className}>
    {showText && !isCompact && (
      <StyledParagraph $isDark={isDark}>
        {hideReviewNumber ? (
          <StyledStrongText>Excellent</StyledStrongText>
        ) : (
          <StyledText>
            Over{' '}
            <StyledStrongText>
              {roundDownToNearestHundred(TRUSTPILOT_REVIEWS_TOTAL)}
            </StyledStrongText>{' '}
            reviews
          </StyledText>
        )}
        <StyledDivider $isDark={isDark}>|</StyledDivider>
        <StyledStrongText>{TRUSTPILOT_RATING_OUT_OF_5}</StyledStrongText>
        {showLabelRating ? ' TrustScore' : null}
        {showLabel ? ` on Trustpilot` : null}
      </StyledParagraph>
    )}
    {isCompact && (
      <StyledText>
        <strong>{TRUSTPILOT_RATING_OUT_OF_5}</strong> /5{' '}
      </StyledText>
    )}
    <ExternalLink href={TRUSTPILOT_URL} ariaLabel="Farewill on Trustpilot">
      <Image
        path={AWARD_LOGO_URL.TRUSTPILOT_RATING_WORDMARK}
        ext="svg"
        width={195}
      />
    </ExternalLink>
  </Wrapper>
)

export default TrustpilotSummary
