import React from 'react'
import styled from 'styled-components'
import { Grid, Image, H, P } from '@farewill/ui'
import { FONT, GTR } from '@farewill/ui/tokens'
import { screenMin } from '@farewill/ui/helpers/responsive'
import { FormatPathArgs } from '@farewill/ui/components/Image/types'
import { CLOUDINARY_ROOT_PATH } from '@farewill/ui/components/Image/constants'

const formatImagePath = ({ path, ext, width }: FormatPathArgs) =>
  `${CLOUDINARY_ROOT_PATH}/e_trim/c_pad,f_auto,q_auto,w_${width}/${path}.${ext}`

const StyledImage = styled(Image)`
  ${screenMin.l`
    transform: translate(
        var(--three-easy-steps-image-optical-adjustment-x, 0),
        var(--three-easy-steps-image-optical-adjustment-y, 0)
      );
  `};
`

const StyledHeading = styled(H)`
  font-weight: ${FONT.WEIGHT.MEDIUM};
`

export type StepProps = {
  /**
   * Path to the image in Cloudinary
   */
  imagePath: string
  /**
   * Optional image width (in pixels) to use when the viewport is smaller than
   * the `L` breakpoint. Defaults to 250px.
   */
  imageWidth?: number
  /**
   * Optional image width (in pixels) to use when the viewport is equal or
   * larger  than the `L` breakpoint. Defaults to 250px. This value can be used
   * (in combination with the `opticalAdjustment` prop) to adjust the image's
   * size so that it appears balanced in size with the images for the other
   * steps.
   */
  imageWidthFromL?: number
  /**
   * Step heading text.
   */
  heading: string
  /**
   * Optional optical adjustments for the image. These can be used to adjust the
   * image's position so that its optical center appears aligned with the images
   * for other steps. This should be used in combination with the
   * `imageWidthFromL` prop to ensure visually similar size & alignment of step
   * images.
   *
   * Values should be provided as strings, ideally in percentages so that the
   * actual px value used is linked to the display size of the image. x/y values
   * provided here will be applied via a `transform: translate` CSS rule on the
   * image.
   */
  opticalAdjustment?: {
    x?: string
    y?: string
  }
  /**
   * Step body content.
   */
  text: string
}

const Step = ({
  imagePath,
  imageWidth = 250,
  imageWidthFromL = 250,
  heading,
  opticalAdjustment,
  text,
}: StepProps): React.ReactElement => (
  <>
    <Grid.Item
      span={8}
      startColumn={1}
      startColumnFromL={1}
      spanFromM={3}
      padding={[0, 0, GTR.M]}
      paddingFromM={[GTR.M, 0]}
      noTrim
    >
      <StyledImage
        formatPath={formatImagePath}
        path={imagePath}
        width={imageWidth}
        widthFromM={imageWidthFromL}
        stretch
        style={
          {
            '--three-easy-steps-image-optical-adjustment-x':
              opticalAdjustment?.x || 0,
            '--three-easy-steps-image-optical-adjustment-y':
              opticalAdjustment?.y || 0,
          } as React.CSSProperties
        }
      />
    </Grid.Item>
    <Grid.Item
      margin={[0, 0, GTR.XXL]}
      marginFromM={0}
      paddingFromM={[GTR.M, 0]}
      spanFromM={5}
      spanFromL={4}
    >
      <StyledHeading size="S">{heading}</StyledHeading>
      <P>{text}</P>
    </Grid.Item>
  </>
)

export default Step
