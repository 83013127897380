import React from 'react'
import styled from 'styled-components'

import { Button, Grid, H, P, Wrapper } from '@farewill/ui'
import { COLOR, FONT, GTR } from '@farewill/ui/tokens'
import { FormatPathArgs } from '@farewill/ui/components/Image/types'
import { CLOUDINARY_ROOT_PATH } from '@farewill/ui/components/Image/constants'
import { screenMin, screenMax } from '@farewill/ui/helpers/responsive'
import { smallCaps } from '@farewill/ui/helpers/text'

import Texture from 'components/Texture'
import { SPECIALISTS } from 'lib/products/constants'
import Specialists from 'components/Specialists'

import CallToAction from '../CallToAction'

const StyledCard = styled(Wrapper)`
  border-radius: 16px;
`

const StyledPill = styled(P)`
  ${smallCaps}

  background-color: ${COLOR.ACCENT.SECONDARY};
  border-radius: 999px;
  display: inline-block;
  color: ${COLOR.WHITE};
  padding: ${GTR.XXS} 10px;
`

const StyledHeading = styled(H)`
  font-weight: ${FONT.WEIGHT.MEDIUM};
`

const StyledWrapper = styled(Wrapper)`
  overflow: hidden;
  position: relative;
`

const StyledTextureDots = styled(Texture)`
  position: absolute;
  min-width: 345px;
  right: 0;
  top: 0;
  width: 10vw;
  z-index: 2;

  ${screenMax.m`
    display: none;
  `};
`

const StyledTextureBlob = styled(Texture)`
  bottom: 0;
  left: -10%;
  max-with: 500px;
  position: absolute;
  top: 55%;
  width: 120%;
  z-index: 1;

  ${screenMin.l`
    bottom: auto;
    left: auto;
    right: 0;
    top: -10px;
    translate: 10% 0%;
    width: 530px;
  `};
`

const StyledContentWrapper = styled(Wrapper)`
  position: relative;
  z-index: 3;
`

const SupportFromExperts = (): React.ReactElement => (
  <StyledWrapper background={COLOR.ACCENT.PRIMARY}>
    <StyledTextureDots
      loading="eager"
      path="textures/dots-pattern-yellow-02"
      formatPath={({ path, ext, width }: FormatPathArgs) =>
        `${CLOUDINARY_ROOT_PATH}/c_scale,f_auto,q_auto,a_270,w_${width}/${path}.${ext}`
      }
      width={300}
    />
    <StyledTextureBlob
      formatPath={({ path, ext, width }: FormatPathArgs) =>
        `${CLOUDINARY_ROOT_PATH}/c_scale,f_auto,q_auto,a_0,w_${width}/${path}.${ext}`
      }
      loading="eager"
      path="textures/blob-yellow-3-120"
      width={500}
    />
    <StyledContentWrapper container>
      <Grid gap={[GTR.L, 0]} gapFromL={GTR.M}>
        <Grid.Item spanFromL={6}>
          <Specialists
            group={SPECIALISTS.PARTNERSHIPS_ACCOUNT_MANAGERS}
            small
          />
          <H decorative margin={[GTR.S, 0]} size="M">
            Support from our legacy experts
          </H>
          <P color={COLOR.BLACK}>
            Whether you’re an experienced legacy manager or you’re just starting
            out, whether you’re part of a team or you work alone, our legacy
            experts have the experience to support you.
          </P>
          <P
            color={COLOR.BLACK}
            margin={[0, 0, GTR.M]}
            marginFromL={[0, 0, GTR.S]}
          >
            You’ll also be invited to our online meet-ups where legacy
            professionals can learn from our experts and each other. Get in
            touch to find out more.
          </P>

          <CallToAction tag={Button.White} trackingId="support-from-experts">
            Partner with Farewill
          </CallToAction>
        </Grid.Item>
        <Grid.Item spanFromL={5} startColumnFromL={8}>
          <StyledCard background={COLOR.WHITE} padding={[GTR.L]}>
            <StyledPill margin={[0, 0, GTR.S]}>Plus</StyledPill>
            <StyledHeading size="S" margin={[0, 0, GTR.S]}>
              Free membership to the ILM
            </StyledHeading>
            <P size="S" margin={[0, 0, GTR.S]}>
              The Institute of Legacy Management (ILM) is the membership body
              for charity legacy professionals. Our partners get free membership
              for their first year, including exclusive access to their bespoke
              mentoring platform, webinars, discounts and more.
            </P>
            <img
              alt="Institute of Legacy Management"
              src="/logos/institute-of-legacy-management.svg"
            />
          </StyledCard>
        </Grid.Item>
      </Grid>
    </StyledContentWrapper>
  </StyledWrapper>
)

export default SupportFromExperts
