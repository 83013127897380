import { useRef, useEffect, useCallback } from 'react'

type AnimationCallback = (deltaTime: number) => void

export const useAnimationFrame = (
  callback: AnimationCallback,
  enabled: boolean,
  reduceMotion: boolean
): void => {
  const requestRef = useRef<number>()
  const previousTimeRef = useRef<number>()
  const hasRenderedFirstFrame = useRef<boolean>(false)

  const animate = useCallback(
    (time: number) => {
      if (!enabled) {
        return
      }

      if (previousTimeRef.current !== undefined) {
        const deltaTime = reduceMotion
          ? 0
          : (time - previousTimeRef.current) / 1000
        callback(deltaTime)
      } else {
        callback(0)
        hasRenderedFirstFrame.current = true
      }

      previousTimeRef.current = time

      if (reduceMotion && hasRenderedFirstFrame.current) {
        return
      }

      requestRef.current = requestAnimationFrame(animate)
    },
    [callback, enabled, reduceMotion]
  )

  useEffect(() => {
    if (enabled) {
      requestRef.current = requestAnimationFrame(animate)
    }
    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current)
      }
    }
  }, [animate, enabled])
}
