export type Logo = {
  name: string
  imagePath: string
  height?: number
}

const ACTION_FOR_CHILDREN: Logo = {
  imagePath: 'partnerships-charity-showcase/action-for-children_kw2kxt',
  name: 'Action for Children',
}

const AGE_UK: Logo = {
  imagePath: 'partnerships-charity-showcase/age-uk_e0d09i',
  name: 'Age UK',
}

const ALZHEIMERS_SOCIETY: Logo = {
  imagePath: 'partnerships-charity-showcase/alzheimers--society_fhjl5h',
  name: 'Alzheimers Society',
}

const CAMPAIGN_AGAINST_LIVING_MISERABLY: Logo = {
  imagePath:
    'partnerships-charity-showcase/campaign-against-living-miserably_o7htu4',
  name: 'Campaign Against Living Miserably',
}

const CAMPAIGN_TO_PROTECT_RURAL_ENGLAND: Logo = {
  imagePath:
    'partnerships-charity-showcase/campaign-to-protect-rural-england_kk6tmo',
  name: 'Campaign to Protect Rural England',
}

const CANCER_RESEARCH_UK: Logo = {
  imagePath: 'partnerships-charity-showcase/cancer-research-uk_qwivkk',
  name: 'Cancer Research UK',
}

const CRISIS: Logo = {
  imagePath: 'partnerships-charity-showcase/crisis_i7gmqa',
  name: 'Crisis',
}

const GREAT_ORMOND_STREET_HOSPITAL_CHILDRENS_CHARITY: Logo = {
  imagePath:
    'partnerships-charity-showcase/great-ormond-street-hospital-childrens-charity_oedsbg',
  name: 'Great Ormond Street Hospital Childrens Charity',
}

const GREENPEACE: Logo = {
  imagePath: 'partnerships-charity-showcase/greenpeace_xqfxra',
  name: 'Greenpeace',
}

const GUYS_AND_ST_THOMAS_CHARITY: Logo = {
  imagePath: 'partnerships-charity-showcase/guys-and-st-thomas-charity_xpk76i',
  name: 'Guys and St Thomas Charity',
}

const HAMPSHIRE_AND_ISLE_OF_WIGHT_AIR_AMBULANCE: Logo = {
  imagePath:
    'partnerships-charity-showcase/hampshire-and-isle-of-wight-air-ambulance_vqpe9v',
  name: 'Hampshire and Isle of Wight Air Ambulance',
}

const HEARING_DOGS_FOR_DEAF_PEOPLE: Logo = {
  imagePath:
    'partnerships-charity-showcase/hearing-dogs-for-deaf-people_xubqgs',
  name: 'Hearing Dogs for Deaf People',
}

const KINGS_COLLEGE_LONDON: Logo = {
  imagePath: 'partnerships-charity-showcase/kings-college-london_wi6wyp',
  name: 'Kings College London',
}

const MACMILLAN_CANCER_SUPPORT: Logo = {
  imagePath: 'partnerships-charity-showcase/macmillan-cancer-support_hwzc5n',
  name: 'Macmillan Cancer Support',
}

const MARIE_CURIE: Logo = {
  imagePath: 'partnerships-charity-showcase/marie-curie_uyx86p',
  name: 'Marie Curie',
}

const MENCAP: Logo = {
  imagePath: 'partnerships-charity-showcase/mencap_lrarg4',
  name: 'Mencap',
}

const MIDLANDS_ARTS_CENTRE: Logo = {
  imagePath: 'partnerships-charity-showcase/midlands-arts-centre_uectur',
  name: 'Midlands Arts Centre',
}

const OXFAM: Logo = {
  imagePath: 'partnerships-charity-showcase/oxfam_mmlscj',
  name: 'Oxfam',
}

const PANCREATIC_CANCER_UK: Logo = {
  imagePath: 'partnerships-charity-showcase/pancreatic-cancer-uk_crfo72',
  name: 'Pancreatic Cancer UK',
}

const ROYAL_NATIONAL_LIFEBOAT_INSTITUTION: Logo = {
  imagePath:
    'partnerships-charity-showcase/royal-national-lifeboat-institution_n1uq08',
  name: 'Royal National Lifeboat Institution',
}

const ROYAL_NORTHERN_COLLEGE_OF_MUSIC: Logo = {
  imagePath:
    'partnerships-charity-showcase/royal-northern-college-of-music_kmthh7',
  name: 'Royal Northern College of Music',
}

const SHELTER: Logo = {
  imagePath: 'partnerships-charity-showcase/shelter_z5riqh',
  name: 'Shelter',
}

const ST_LUKES_HOSPICE_PLYMOUTH: Logo = {
  imagePath: 'partnerships-charity-showcase/st-lukes-hospice-plymouth_a8vqkq',
  name: 'St Lukes Hospice Plymouth',
}

const TEENAGE_CANCER_TRUST: Logo = {
  imagePath: 'partnerships-charity-showcase/teenage-cancer-trust_ldpqj0',
  name: 'Teenage Cancer Trust',
}

const TRUSSELL_TRUST: Logo = {
  imagePath: 'partnerships-charity-showcase/trussell-trust_z4aoz4',
  name: 'Trussell Trust',
}

const WOOD_GREEN: Logo = {
  imagePath: 'partnerships-charity-showcase/wood-green_ls1qk5',
  name: 'Wood Green',
}

const WORLD_VISION: Logo = {
  imagePath: 'partnerships-charity-showcase/world-vision_dvapot',
  name: 'World Vision',
}

export const SCROLLING_LOGO_LIST: Logo[] = [
  ACTION_FOR_CHILDREN,
  AGE_UK,
  ALZHEIMERS_SOCIETY,
  CAMPAIGN_AGAINST_LIVING_MISERABLY,
  CAMPAIGN_TO_PROTECT_RURAL_ENGLAND,
  CANCER_RESEARCH_UK,
  CRISIS,
  GREAT_ORMOND_STREET_HOSPITAL_CHILDRENS_CHARITY,
  GREENPEACE,
  GUYS_AND_ST_THOMAS_CHARITY,
  HAMPSHIRE_AND_ISLE_OF_WIGHT_AIR_AMBULANCE,
  HEARING_DOGS_FOR_DEAF_PEOPLE,
  KINGS_COLLEGE_LONDON,
  MACMILLAN_CANCER_SUPPORT,
  MARIE_CURIE,
  MENCAP,
  MIDLANDS_ARTS_CENTRE,
  OXFAM,
  PANCREATIC_CANCER_UK,
  ROYAL_NATIONAL_LIFEBOAT_INSTITUTION,
  ROYAL_NORTHERN_COLLEGE_OF_MUSIC,
  SHELTER,
  ST_LUKES_HOSPICE_PLYMOUTH,
  TEENAGE_CANCER_TRUST,
  TRUSSELL_TRUST,
  WOOD_GREEN,
  WORLD_VISION,
]
