import React from 'react'

import { H, Wrapper } from '@farewill/ui'
import { BREAKPOINT, GTR } from '@farewill/ui/tokens'

import { TAGS } from 'lib/trustpilot/constants'
import BreakpointSwitch from 'components/BreakpointSwitch'
import TrustpilotCarousel from 'components/TrustpilotWidget/TrustpilotCarousel'

const Trustpilot = (): React.ReactElement => (
  <Wrapper container>
    <BreakpointSwitch
      breakAt={BREAKPOINT.M}
      belowComponent={null}
      aboveComponent={
        <H centered margin={[0, 0, GTR.L]} size="M">
          Voted the nation’s favourite will writing service
        </H>
      }
    />
    <TrustpilotCarousel container={false} tag={TAGS.PARTNERSHIPS} />
  </Wrapper>
)

export default Trustpilot
